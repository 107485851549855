/* SCRIPTS */


function setOffsetPosition($this) {
    var rect = $this[0].getBoundingClientRect();
    var win = $this[0].ownerDocument.defaultView;
    return {
        top: rect.top,
        left: rect.left
    };
}

$(document).ready(function() {

    $(".o-breadcrumbs .post-news-archive").click(function(e) {
        e.preventDefault();
    });

    /* POPUP */

    setTimeout(function() {
        $("#popup").addClass("visible");
    }, 3500);

    $("#popup-close").click(function(e) {
        $("#popup").removeClass("visible");
    })

    /* VIDEO */

    if ($("#video-data").length) {
        var thumb = $("#video-data").attr("data-src-thumb");

        $("#video-link").click(function(e) {
            e.preventDefault();
            $("#video-full").addClass("visible");
            var full = $("#video-data").attr("data-src-full");
            $("#video-container").html("<video width='100%' height='100%' controls autoplay id='video'><source src='" + full + "' type='video/mp4'></video>");
        });

        $("#video-bg, #video-close").click(function(e) {
            e.preventDefault();
            $("#video-full").removeClass("visible");
            $('#video').trigger("pause");
        });
    }

    /* ROUTE PLANNER */

    $('body').on('click', '.c-route__menu-link', function(e) {
        e.preventDefault();
        $(".c-route__menu-link, .c-route__map, .c-route__content").removeClass("active");
        $("." + $(this).attr("data-id")).addClass("active");
        $(this).addClass("active");
    });

    /* MENU */

    $('body').on('click', '#hamburger', function(e) {
        if ($(this).hasClass("c-header__hamburger--active")) {
            $(this).removeClass("c-header__hamburger--active");
            $(".menu-main-menu-container").removeClass("menu-main-menu-container--active");
        } else {
            $(this).addClass("c-header__hamburger--active");
            $(".menu-main-menu-container").addClass("menu-main-menu-container--active");
        }
    });


    $('body').on('click', '#hamburger-mobile', function(e) {
        if ($(this).hasClass("c-header-mobile__hamburger--active")) {
            $(this).removeClass("c-header-mobile__hamburger--active");
            $(".c-header-mobile__menu-container").removeClass("c-header-mobile__menu-container--active");
        } else {
            $(this).addClass("c-header-mobile__hamburger--active");
            $(".c-header-mobile__menu-container").addClass("c-header-mobile__menu-container--active");
        }
    });

    /* NEWS */

    $(".c-news__item-tag, .c-news-single__tag").each(function() {
        if ($(this).html() == "informace" || $(this).html() == "Information") {
            $(this).addClass("info");
        } else if ($(this).html() == "event") {
            $(this).addClass("event");
        } else if ($(this).html() == "akční nabídka" || $(this).html() == "Special offer") {
            $(this).addClass("action");
        }
    });

    /* NEWS LINKS IN BREADCRUMS */

    $(".post-news-archive").each(function() {
        if ($(this).attr("href") == "https://www.centrumstromovka.cz/news/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/novinky/");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/novinky/";
            });
        } else if ($(this).attr("href") == "https://www.centrumstromovka.cz/en/news/") {
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/en/news/";
            });
        }

    });

    /* GALLERY LINKS IN BREADCRUMBS */

    $(".post-gallery-archive").each(function() {

        if ($(this).attr("href") == "https://www.centrumstromovka.cz/gallery/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/galerie");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/galerie/";
            });
        } else if ($(this).attr("href") == "https://www.centrumstromovka.cz/en/gallery/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/en/img-gallery/");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/en/img-gallery/";
            });
        }
    });

    /* CAMPAINGS LINKS IN BREADCRUMBS */

    $(".post-campaigns-archive").each(function() {

        if ($(this).attr("href") == "https://www.centrumstromovka.cz/campaigns/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/";
            });
        } else if ($(this).attr("href") == "https://www.centrumstromovka.cz/en/campaigns/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/en/");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/en/";
            });
        }
    });

    /* JOBS LINKS IN BREADCRUMBS */

    $(".post-jobs-archive").each(function() {

        if ($(this).attr("href") == "https://www.centrumstromovka.cz/jobs/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/kariera-v-nakupnim-centru/");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/kariera-v-nakupnim-centru/";
            });
        } else if ($(this).attr("href") == "https://www.centrumstromovka.cz/en/kariera-v-nakupnim-centru/") {
            $(this).attr("href", "https://www.centrumstromovka.cz/en/kariera-v-nakupnim-centru/");
            $(this).click(function() {
                window.location.href = "https://www.centrumstromovka.cz/en/kariera-v-nakupnim-centru/";
            });
        }
    });

    /* DETAIL MAP */

    $('body').on('click', '.c-detail__show-map', function(e) {
        $('html,body').animate({ scrollTop: $("#detail-map").offset().top - 20 });
    });

    var detailMapId = $("#detail-map").attr("data-id");
    var detailMapFloor = $("#detail-map").attr("data-floor");

    $("#detail-floor-" + detailMapFloor).addClass("c-detail__map-img--active");
    $(".c-detail__map-img svg g#x" + detailMapId).addClass("active");

    /* FLOORS on the MAP */

    if ($(".c-detail__map").attr("data-floor") == "0" && $("#detail-map").attr("data-id") == "0000") {
        $("#detail-floor-cz").html("Parking -2");
        $("#detail-floor-en").html("Parking -2");
        $("#detail-floor-" + detailMapFloor).removeClass("c-detail__map-img--active");
        $(".c-detail__floor").addClass("c-detail__floor--garage")
    } else if ($(".c-detail__map").attr("data-floor") == "0" && $("#detail-map").attr("data-id") != "000") {
        $("#detail-floor-cz").html("přízemí");
        $("#detail-floor-en").html("ground floor");
    } else if ($(".c-detail__map").attr("data-floor") == "0" && $("#detail-map").attr("data-id") == "000") {
        $("#detail-floor-cz").html("Parking -1");
        $("#detail-floor-en").html("Parking -1");
        $("#detail-floor-" + detailMapFloor).removeClass("c-detail__map-img--active");
        $(".c-detail__floor").addClass("c-detail__floor--garage")
    } else if ($(".c-detail__map").attr("data-floor") == "1") {
        $("#detail-floor-cz").html("1.patro");
        $("#detail-floor-en").html("1st floor");
    } else {
        $("#detail-floor-cz").html("2.patro");
        $("#detail-floor-en").html("2nd floor");
    }

    $('.c-map__list-id').each(function(){
        if($(this).attr('data-id') == '000') {
            $(this).text('-1');
        }
        else if($(this).attr('data-id') == '0000') {
            $(this).text('-2');
        }
    });

    /* LIGHTGALLERY */

    $(".wp-block-gallery li").each(function() {
        $(this).attr("href", $(this).find("a").attr("href"));
    });

    if ($(".blocks-gallery-grid").length > 0) {
        $(".blocks-gallery-grid").lightGallery();
    } else {
        $(".wp-block-gallery").lightGallery();
    }


    /* BANNER */

    $("#hp-banner").hide();

    if ($(".c-intro__banner").length > 0) {

        setTimeout(function() {
            $(".c-intro__banner").each(function() {
                var src = $(this).attr("data-src");
                var extension = src.substr(src.length - 3);
                if (extension == "jpg" || extension == "png") {
                    $(this).find(".c-intro__banner-inner").html("<img src='" + src + "' alt=''>");
                } else {
                    $(this).find(".c-intro__banner-inner").html("<video width='100%' loop preload autoplay muted playsinline controls><source src='" + src + "'></video>");
                }

                if ($(this).find("a").attr("href") == "") {
                    $(this).find("a").css("cursor", "auto").click(function(e) { e.preventDefault() });
                }
            });

            if ($(".c-intro__banner").length > 1) {

                setTimeout(function() {

                    $('#hp-banner').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        arrows: false,
                        dots: true,
                        adaptiveHeight: true,
                        draggable: true
                    });
                }, 20);
            }
            $("#hp-banner").fadeIn();
        }, 2000);

    }

    // NEWS

    $('.js-news-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        draggable: true
    });

    /* Discounts */


    $('#discounts-item-slick').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        draggable: true,
        infinite: true,
        responsive: [{
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });


    $('#discounts-slick').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        draggable: true
    });

    /* Discounts title */

    if ($('.js-discount-title').length > 0) {
        $('.js-discount-title').each(function() {
            var target = $(this).find('.js-discount-type span');
            var source = $(this).find('.js-discount-value');

            target.html(source.html().trim());

            $('.js-discount-title').addClass('js-discount-title--visible')
        });
    }


    /* SANITIZE LINK */

    if ($(".c-detail__content-link--URL").length > 0) {

        var el = $(".c-detail__content-link--URL a");
        var href = el.attr("href");

        if (href.startsWith("www")) {
            var newHref = "http://" + href;
        } else if (href.startsWith("http://")) {
            return true;
        } else if (href.startsWith("https://")) {
            return true;
        } else {
            var newHref = "http://" + href;
        }

        el.attr("href", newHref);
    }

    /* COOKIES */

    if ($.cookie('cookies-consent') != "true") {
        $("#cookies-bar").addClass('visible');
    }

    $("#accept-cookies").click(function(e) {
        $("#cookies-bar").removeClass('visible');
        $.cookie('cookies-consent', 'true', { expires: 365, path: '/' });
    });

    // ABOUT US ANCHORS
    $('.js-anchor').click(function(e){
        e.preventDefault();
        var thisEl = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(thisEl).offset().top - 100
        }, 500);
    });

    $('.js-about-gallery-show-all').click(function(e){
        e.preventDefault();
        $(this).hide();
        $('.js-about-gallery-item').addClass('c-gallery-about__item--visible');
    });
});

/* DISCOUNT SELECT */

$(document).on('change', '#discounts-select', function(){
    $('.c-discounts__item').hide();
    $('.c-discounts__item--' + $(this).val()).show();
    if($(this).val() == 'all') {
        $('.c-discounts__item').show();
    }
});

/* PLACE MARKER ON THE MAP */

$(window).on('load', function() {
    if ($(".c-detail__logo").length > 0 && $(".c-detail__map").attr("data-id") != "000") {
        $(".c-detail__logo img").clone().prependTo(".c-detail__map-img").addClass("c-detail__map-logo").wrap("<div class='c-detail__map-logo-cont'></div>");
        $("<div class='c-detail__map-arrow'></div>").prependTo(".c-detail__map-logo-cont");
        var childPos = setOffsetPosition($(".c-detail__map-img g.active"));
        var parentPos = setOffsetPosition($(".c-detail__map-img").parent());
        var logoWidth = $(".c-detail__logo img").width();
        var logoHeight = 28;
        var childOffset = {
            top: childPos.top - parentPos.top - logoHeight - 30,
            left: childPos.left - parentPos.left
        }

        if ($(".c-detail__map-img g.active").attr("id") == "x014") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 30,
                left: childPos.left - parentPos.left + 40
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x212") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight + 50,
                left: childPos.left - parentPos.left + 80
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x010") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight,
                left: childPos.left - parentPos.left
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x105") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 30,
                left: childPos.left - parentPos.left + 30
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x108") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 30,
                left: childPos.left - parentPos.left + 30
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x113") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight,
                left: childPos.left - parentPos.left - 3
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x114") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight + 70,
                left: childPos.left - parentPos.left + 30
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x001") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 20,
                left: childPos.left - parentPos.left + 40
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x224") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 10,
                left: childPos.left - parentPos.left
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x022") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 24,
                left: childPos.left - parentPos.left + 10
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x023") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 24,
                left: childPos.left - parentPos.left + 5
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x024") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 24,
                left: childPos.left - parentPos.left + 5
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "´x019") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 40,
                left: childPos.left - parentPos.left
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x223") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 20,
                left: childPos.left - parentPos.left
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x125") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight + 30,
                left: childPos.left - parentPos.left
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x126") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 10,
                left: childPos.left - parentPos.left + 10
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x127") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 24,
                left: childPos.left - parentPos.left + 10
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x131") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight,
                left: childPos.left - parentPos.left
            }
        } else if ($(".c-detail__map-img g.active").attr("id") == "x102") {
            var childOffset = {
                top: childPos.top - parentPos.top - logoHeight - 10,
                left: childPos.left - parentPos.left
            }
        }



        

        $(".c-detail__map-logo-cont").css("left", childOffset.left).css("top", childOffset.top);

    }
});