/* MAP */

$(document).ready(function() {

	if($('.c-map__image-container').length > 0) {

		$(".c-map__list-item--floor-0").addClass("visible");

		$(".c-map__list-id").each(function(){
			var bgColor = $("#maps g#x" + $(this).attr("data-id") + " [class^='st']").css("fill");
			$(this).css("background-color", bgColor);
		});

		/* MAP ACTIONS */

		$('body').on('click', "#maps g[id^='x']", function(e) {
			var id = $(this).attr("id");
			$(".c-map__detail-item, .c-map__list-item, #maps g[id^='x']").removeClass('active selected');
			$("#vendor-item-" + id).addClass('active');
			$("#list-id-" + id).addClass('active');
			$(".c-map__list-item--id-" + id).addClass('active');
			$(this).addClass('active');
			$(".c-map__image").removeClass('selected');
			$(this).parent().parent().addClass("selected");
		});

		$('body').on('click', '.c-map__menu-link', function(e) {
			e.preventDefault();
			var id = $(this).attr("data-id");
			$(".c-map__menu-link, .c-map__image, .c-map__list-item").removeClass('active');
			$(this).addClass('active');
			$("#" + id).addClass('active');
			$("#list-id-" + id).addClass('visible');
			$(".c-map__list-item--id-" + id).addClass('active');
			$(".c-map__list-item").removeClass('visible');
			$(".c-map__list-item--" + id).addClass('visible');
		});

		$('body').on('click', '.c-map__list-item', function(e) {		
			var id = $(this).attr("data-id");
			var floor = $(this).attr("data-floor");				
			$(".c-map__menu-link, .c-map__image, #maps g[id^='x'], .c-map__list-item, .c-map__detail-item").removeClass('active selected');
			$(this).addClass('active');
			$("#floor-menu-" + floor).addClass('active');
			$("#floor-" + floor).addClass('active selected');
			$("#vendor-item-x" + id).addClass('active');
			$("#x" + id).addClass('active');
			$('html,body').animate({scrollTop: $("#maps").offset().top - 20});
		});

		$('body').on('mouseover', '.c-map__list-item', function(e) {
			var id = $(this).attr("data-id");
			$(this).addClass('hover');
			$("#vendor-item-x" + id).addClass('hover');
			$("#x" + id).addClass('hover');
		});

		$('body').on('mouseout', '.c-map__list-item', function(e) {		
			var id = $(this).attr("data-id");
			$(this).removeClass('hover');
			$("#vendor-item-x" + id).removeClass('hover');
			$("#x" + id).removeClass('hover');
		});

		/* SHOW LOCATION ON THE MAP VIA URL */

		function parseURLParams(url) {
		    var queryStart = url.indexOf("?") + 1,
		        queryEnd = url.indexOf("#") + 1 || url.length + 1,
		        query = url.slice(queryStart, queryEnd - 1),
		        pairs = query.replace(/\+/g, " ").split("&"),
		        parms = {},
		        i, n, v, nv;

		    if (query === url || query === "") return;

		    for (i = 0; i < pairs.length; i++) {
		        nv = pairs[i].split("=", 2);
		        n = decodeURIComponent(nv[0]);
		        v = decodeURIComponent(nv[1]);

		        if (!parms.hasOwnProperty(n)) parms[n] = [];
		        parms[n].push(nv.length === 2 ? v : null);
		    }
		    return parms;
		}

		if(typeof parseURLParams(window.location.href) != 'undefined') {
			var mapId = parseURLParams(window.location.href).mapId[0];
			var mapFloor = parseURLParams(window.location.href).mapFloor[0];
			$(".c-map__image, .c-map__menu-link, .c-map__detail-item, #maps g[id^='x']").removeClass('active');
			$("#floor-" + mapFloor).addClass('active selected');
			$("#floor-menu-" + mapFloor).addClass('active');
			$("#vendor-item-x" + mapId).addClass('active');
			$("#maps g#x" + mapId).addClass('active');
			$(".c-map__list-item--id-x" + mapId).addClass('active');
			$(".c-map__list-item").removeClass("visible");
			$(".c-map__list-item--floor-" + mapFloor).addClass('visible');
		}

		/* MAP IMAGES */

		$('body').on('mouseover', "#maps g[id^='x']", function() {
			var id = $(this).attr("id");
			var el = $("#vendor-logo-" + id);
			var childPos = setOffsetPosition($(this));
			var parentPos = setOffsetPosition($(".c-map__image-container").parent());
			var childOffset = {
				top: childPos.top - parentPos.top,
				left: childPos.left - parentPos.left
			}
			el.prependTo(".c-map__image-container");
			$(".c-map__detail-item-image").removeClass("visible");
			el.css("left", childOffset.left).css("top", childOffset.top - 100).addClass("visible");
		});

		$('body').on('mouseout', "#maps g[id^='x']", function() {
			var id = $(this).attr("id");
			var el = $("#vendor-logo-" + id);
			$(".c-map__detail-item-image").removeClass("visible");
			el.removeClass("visible");
		});

		$('body').on('mouseover', ".c-map__detail-item-image", function() {
			$(this).addClass("visible");
		});

		$('body').on('mouseout', ".c-map__detail-item-image", function() {
			$(this).removeClass("visible");
		});

	}
});

